import React from 'react';
import { RootState, useSelector } from '../redux-persist/store';

//i18n

function CustomerName() {
    const  {customer} = useSelector(
        (state: RootState) => state.customer
    );

    return (
        <h5 className="rounded-circle-profile  "> {customer.firstName?.slice(0, 1).toUpperCase()}{customer.lastName?.slice(0, 1).toUpperCase()} </h5>
    );
}

export default CustomerName;