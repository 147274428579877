//{{ga-receiver-profile}}/user/me/contact-groups/:groupId/contacts
//{{ga-receiver-profile}}/user/me/contact-groups/:groupId/contacts

import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
import URLConstants from "../../constants/urlConstants";
import { AllContacts } from '../../@types/allContacts';

type GetTemplatesState = {
  isLoading: boolean;
  error: boolean;
  contactsFromGroup: AllContacts[];
};

const initialState: GetTemplatesState = {
  isLoading: false,
  error: false,
  contactsFromGroup: [],
};

const slice = createSlice({
  name: "getContactsFromGroup",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL TEMPLATES
    getContactsSuccess(state, action) {
      state.isLoading = false;
      state.contactsFromGroup = action.payload;
    },
    getMultiContactsSuccess(state, action) {
      state.isLoading = false;
      state.contactsFromGroup = state.contactsFromGroup.concat(action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

export function getAllContactFromGroup(groupId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${URLConstants.CONTACT_GROUP_BASE_URL}/${groupId}/contacts`
      );
      dispatch(slice.actions.getContactsSuccess(response.data.data));
      return {contacts:response.data.data};
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return { message: error?.message, hasError: true };
    }
  };
}

export function getAllContactFromMultiGroup(groups: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      groups.forEach(async (group: any, index: number) => {
        const response = await axios.get(
          `${URLConstants.CONTACT_GROUP_BASE_URL}/${group.groupId}/contacts`
        );
        if (index == 0) {
          dispatch(slice.actions.getContactsSuccess(response.data.data));
        } else {
          dispatch(slice.actions.getMultiContactsSuccess(response.data.data));
        }
      });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return { message: error?.message, hasError: true };
    }
  };
}

export function addContactToGroup(contactId: any,groupId:string) {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${URLConstants.CONTACT_GROUP_BASE_URL}/${groupId}/contacts`,contactId
      );
      dispatch(getAllContactFromGroup(groupId));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return { message: error?.message, hasError: true };
    }
  };
}
